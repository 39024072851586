<template>
  <div>
  <h3>Customers</h3>
  <v-btn
      text
      @click="openCreateUser()"
      outlined
      class="btn btn-lg btn-add-main"
      style="background: transparent"
  >
    <i class="fas fa-plus-circle"></i> Add New Customer
  </v-btn>
    <KTPortlet class="mt-2">
        <template v-slot:body>
            <v-app>
              <div class="row" v-if="loading && customers && customers.length<1">
                <div class="col-md-12">
                  <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                </div>
              </div>
              <div class="row" v-else-if="!loading && customers && customers.length<1">
                <div class="col-md-12 text-center">
                  <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                  <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                </div>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="6">
                    Total Customers : <strong>{{meta.total}}</strong>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    Total Verified Customers : <strong>{{meta.total_verified_customers}}</strong>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="3">
                    <v-text-field dense label="Name" v-model="search.name" @input="getCustomers"
                                  outlined></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="2">
                    <v-text-field dense label="Phone" v-model="search.phone" @input="getCustomers"
                                  outlined></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="2">
                    <v-text-field dense label="Email" v-model="search.email" @input="getCustomers"
                                  outlined></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="2">
                    <v-select
                        @change="getCustomers"
                        :items="websites"
                        v-model="search.website_id"
                        dense
                        label="Websites"
                        item-text="title"
                        item-value="id"
                        outlined
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="1">
                    <v-btn @click="resetFilter()">
                      <i class=""></i> Reset
                    </v-btn>
                  </v-col>
                  <v-col class cols="12" md="12" sm="12">
                    <v-data-table hide-default-footer :headers="headers" :items="customers">
                      <template v-slot:item.is_active="data">
                        <i
                            v-if="data.item.is_active"
                            class="kt-nav__link-icon flaticon2-check-mark text-success"
                        ></i>
                        <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                      </template>
                      <template v-slot:item.is_email_verified="data">
                        <i
                            v-if="data.item.is_email_verified"
                            class="kt-nav__link-icon flaticon2-check-mark text-success"
                        ></i>
                        <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                      </template>
                      <template v-slot:item.action="data">
                        <div class="kt-widget__toolbar">
                          <a
                              href="#"
                              class="btn btn-clean btn-sm btn-icon btn-icon-md"
                              data-toggle="dropdown"
                          >
                            <i class="flaticon-more-1"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                            <ul class="kt-nav">
                              <li class="kt-nav__item">
                                <router-link
                                    :to="{ name: 'customers-address', params: { userId: data.item.id }}"
                                    class="kt-nav__link"
                                >
                                  <i class="kt-nav__link-icon fa fa-map-marker"></i>
                                  <span class="kt-nav__link-text">View Address</span>
                                </router-link>
                              </li>
                              <li class="kt-nav__item">
                                <a href @click.prevent="editCustomer(data.item)" class="kt-nav__link">
                                  <i class="kt-nav__link-icon flaticon-edit"></i>
                                  <span class="kt-nav__link-text">Edit</span>
                                </a>
                              </li>

<!--                              <li class="kt-nav__item">-->
<!--                                <a href="#" @click.prevent="editCustomer(data.item)"-->
<!--                                   class="kt-nav__link">-->
<!--                                  <i class="kt-nav__link-icon flaticon-eye"></i>-->
<!--                                  <span class="kt-nav__link-text">View</span>-->
<!--                                </a>-->
<!--                              </li>-->
                              <li class="kt-nav__item">
                                <a href="#" @click.prevent="resetPassword(data.item)" class="kt-nav__link">
                                  <i class="kt-nav__link-icon fa fa-recycle"></i>
                                  <span class="kt-nav__link-text">Reset password</span>
                                </a>
                              </li>
                              <li class="kt-nav__item" v-if="data.item.is_active">
                                <a
                                    href="#"
                                    class="kt-nav__link"
                                    @click.prevent="activateOrDeactivate(data.item)"
                                >
                                  <i class="kt-nav__link-icon flaticon-close"></i>
                                  <span class="kt-nav__link-text">Deactivate Account</span>
                                </a>
                              </li>
                              <li class="kt-nav__item" v-else>
                                <a
                                    href="#"
                                    class="kt-nav__link"
                                    @click.prevent="activateOrDeactivate(data.item)"
                                >
                                  <i class="kt-nav__link-icon flaticon-close"></i>
                                  <span class="kt-nav__link-text">Activate Account</span>
                                </a>
                              </li>
                              <!--                                            <li class="kt-nav__item">-->
                              <!--                                                <a-->
                              <!--                                                    href="#"-->
                              <!--                                                    @click.prevent="deleteCustomer(data.item.id)"-->
                              <!--                                                    class="kt-nav__link"-->
                              <!--                                                >-->
                              <!--                                                    <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>-->
                              <!--                                                    <span class="kt-nav__link-text">Delete</span>-->
                              <!--                                                </a>-->
                              <!--                                            </li>-->
                            </ul>
                          </div>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-dialog scrollable v-model="dialog" persistent max-width="900px">
                    <v-card v-if="edit">
                      <v-card-title>
                        <span class="headline">Customer Detail</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <div class="form-block">
                            <v-row>
                              <v-col cols="6" md="6">
                                <v-text-field
                                    label="First Name"
                                    v-model="customer.first_name"
                                    required
                                    densed
                                    outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6" md="6">
                                <v-text-field
                                    label="Last Name"
                                    v-model="customer.last_name"
                                    required
                                    densed
                                    outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6" md="6">
                                <v-text-field
                                    label="Phone"
                                    v-model="customer.phone"
                                    required
                                    densed
                                    outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6" md="6" v-if="!customer">
                                <v-text-field
                                    label="Email"
                                    v-model="customer.email"
                                    required
                                    densed
                                    outlined
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6" md="6" sm="6" class="text-right">
                                <v-row>
                                  <v-switch label="Status" class="m-2"
                                            v-model="customer.is_active"></v-switch>
                                  <v-switch
                                      label="Verified Email"
                                      class="m-2"
                                      v-model="customer.is_email_verified"
                                  ></v-switch>
                                </v-row>
                              </v-col>
                            </v-row>
                          </div>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                        <v-btn
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            text
                            @click.prevent="updateCustomer"
                            :isBusy="isBusy"
                        >{{ edit ? 'Update' : 'Save' }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog scrollable v-model="resetPasswordDialog" persistent max-width="400px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Reset Customer Password</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <div class="form-block">
                            <v-row>
                              <v-col cols="12" md="12">
                                <v-text-field
                                    label="Password"
                                    v-model="customer.password"
                                    required
                                    dense
                                    :type="show1 ? 'text' : 'password'"
                                    @click:append="show1 = !show1"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    outlined
                                ></v-text-field>
                              </v-col>

                            </v-row>
                          </div>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                        <v-btn
                            color="text-white v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            text
                            @click.prevent="changePassword"
                            :isBusy="isBusy"
                        >Reset
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>

            </v-app>
        </template>
    </KTPortlet>
  </div>
</template>
<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import WebsiteService from "@/services/Websites/WebsiteService";
    import WebsiteCustomerService from "@/services/store/EcommerceCustomerService";

    const websiteService = new WebsiteService();

    const WebsiteCustomer = new WebsiteCustomerService();
    export default {
        name: "customer",
        data: () => {
            return {
                userId: null,
                loading: true,
                isBusy: false,
              show1: false,
              resetPasswordDialog: false,
                badge_color: null,
                search: {},
                edit: false,
                count: {},
                dialog: false,
                customer: {
                    email: null,
                    first_name: null,
                    last_name: null,
                    is_active: null,
                    phone: null
                },
                meta : {},
                customers: [],
                websites: [],
                headers: [
                    {
                        text: "First Name",
                        align: "start",
                        sortable: false,
                        value: "first_name"
                    },
                    {text: "Last Name", value: "last_name"},
                    {text: "Phone", value: "phone"},
                    {text: "Email", value: "email"},
                    {text: "Email Verification", value: "is_email_verified"},
                    {text: "Status", value: "is_active"},
                    {text: "Action", value: "action"}
                ]
            };
        },
        components: {
            KTPortlet
        },
        methods: {
          resetPassword(user){
            this.resetPasswordDialog = true;
            this.customer = {};
            this.customer = user;
            this.customer.password = null;

          },
            getCustomers() {
                WebsiteCustomer.paginate(this.userId, this.search).then(res => {
                    this.customers = res.data.data;
                    this.meta = res.data.meta;
                  this.loading=false;
                });
            },
            getAllWebsite() {
                websiteService
                    .getAll()
                    .then(response => {
                        this.websites = response.data.data;
                      this.loading=false;
                    })
                    .catch(error => {
                    });

            },
            editCustomer(item) {
                // console.log(item);
                this.edit = true;
                this.customer = item;
                this.openDialog();
            },
            activateOrDeactivate(item) {
                // console.log(item);
                this.$confirm(
                    {
                        message: `Are you sure you want to update customer settings ?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                this.customer = item;
                                if (this.customer.is_active ) {
                                    this.customer.is_active = false;
                                    this.updateCustomer();
                                } else {
                                    this.customer.is_active = true;
                                    this.is_email_verified = true;
                                    this.updateCustomer();
                                }
                            }
                        }
                    }
                )

            },
            updateCustomer() {
                this.isBusy = true;
                WebsiteCustomer.update(this.userId, this.customer.id, this.customer).then(
                    res => {
                        this.isBusy = false;
                        this.reset();
                        this.$snotify.success("Customer profile updated");
                        this.getCustomers();
                    }
                );
            },   changePassword() {
                this.isBusy = true;
                WebsiteCustomer.changePassword(this.userId, this.customer.id, this.customer).then(
                    res => {
                        this.isBusy = false;
                        this.reset();
                        this.resetPasswordDialog = false;
                        this.$snotify.success("Customer Password updated");
                        this.getCustomers();
                    }
                );
            },
            deleteCustomer(id) {
                this.$confirm(
                    {
                        message: `Are you sure you want to delete this customer ?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteCustomer.delete(this.userId,id).then(res => {
                                    this.$snotify.success("Customer profile deleted");
                                    this.getCustomers();
                                });
                            }
                        }
                    }
                )

            },
            openDialog() {
                this.dialog = true;
            },
            reset() {
                (this.customer = {}), this.closeDialog();
            },
            resetFilter() {
                this.search = {};
            },

            closeDialog() {
                this.dialog = false;
            },
            openCreateUser() {
                this.$router.push({
                    name: "customers-create",
                    params: {domainname: this.siteUrl}
                });
            }
        },
        mounted() {
            this.userId = this.$store.getters.currentUser.id;
            this.getAllWebsite();
            this.getCustomers();
        },
        watch: {
            $route() {
                this.search.status = this.$route.params.status;
                this.getCustomers();
            }
        }
    };
</script>
